import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import CustomPlaceInput from "components/CustomPlaceInput/CustomPlaceInput";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { TextField, CircularProgress, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  isAdmin,
  isSubContractor,
  REQUEST_STATUS,
  REQUEST_TYPE,
} from "../../../constants";
import moment from "moment";
import { getUserOffice } from "redux/requests/selectors";

const NewRequestShiftTeam = ({
  request,
  onSaveShift,
  isOffice,
  onClose,
  ...rest
}) => {
  const { cars } = useSelector((state) => state.cars);
  const isRoleOffice = useSelector(getUserOffice);
  const { agents } = useSelector((state) => state.agents);

  const [isAttachError, setAttachError] = useState(false);
  const { saving } = useSelector((state) => state.requests);

  const defaultShift = {
    // pup: request.pup,
    // dop: request.dop,
    // start_date: moment(request.start_date),
    request_team: [
      {
        id: Math.random(),
        agents: [],
        team_client_transport: [],
      },
    ],
  };
  const prepareDefaultShift = () => {
    return defaultShift;
  };
  const prepareExistingShift = (existingShift) => {
    existingShift.start_date = moment(existingShift.start_date);
    if (existingShift.end_date)
      existingShift.end_date = moment(existingShift.end_date);
    return existingShift;
  };
  const [shift, setShift] = React.useState(
    !!rest.shift ? prepareExistingShift(rest.shift) : prepareDefaultShift()
  );

  const onDataChanged = (key, value) => {
    setShift({
      ...shift,
      [key]: value,
    });
  };

  const onAddNewTeam = () => {
    const { request_team } = shift;
    request_team.push({
      id: Math.random(),
      agents: [],
      team_client_transport: [],
    });
    setShift({
      ...shift,
      request_team,
    });
  };

  const onRemoveTeam = (team) => {
    let { request_team } = shift;
    request_team = request_team.filter((x) => x.id != team.id);
    setShift({
      ...shift,
      request_team,
    });
  };

  const onTeamChanged = (teamIndex, key, value) => {
    let { request_team } = shift;
    request_team[teamIndex] = {
      ...request_team[teamIndex],
      [key]: value,
    };
    setShift({
      ...shift,
      request_team,
    });
  };
  const isValidDate = (date) => {
    if (date instanceof moment) {
      return true;
    }
    return false;
  };

  function uniqBy(a, key) {
    return [...new Map(a.map((x) => [key(x), x])).values()];
  }
  const validateShift = (request) => {
    const client_transport = request.client_transport;
    // for (let i = 0; i < requestShifts.length; i++) {
    let attachedTrucks = [];
    const requestShift = shift;
    if (!requestShift.start_date || !isValidDate(requestShift.start_date))
      return `the start date is not valid `;
    if (!requestShift.pup) return `the PUP is missing`;
    if (!requestShift.dop && request.type === REQUEST_TYPE.ESCORT)
      return `the DOP is missing`;
    for (let j = 0; j < requestShift.request_team.length; j++) {
      const team = requestShift.request_team[j];
      attachedTrucks = attachedTrucks.concat(
        team.team_client_transport.map((x) =>
          x.client_transport ? x.client_transport.id : x.id
        )
      );
      if (request.type !== REQUEST_TYPE.STATIC) {
        if (!team.security_car)
          return `Team ${j + 1} :  the Security car is missing`;

        if (team.team_client_transport.length === 0)
          return `Team ${j + 1} : attached truck(s) missing`;

        const unique = uniqBy(attachedTrucks, (x) => x);
        if (unique.length < client_transport.length)
          return `please attach all the trucks per shift`;
      }

      if (team.agents.length === 0) return `Team ${j + 1} : Agent(s) missing`;
    }
    // }

    return true;
  };

  const isAccepted =
    (request.request_shift.length > 0 &&
      request.request_shift[0].subcontractor_status == 1) ||
    request.subcontractor_status == 1;
  if (!isAccepted && isSubContractor) {
    return (
      <Card style={{ backgroundColor: "#fff", padding: 20 }}>
        <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
          You have to accept the request to be able to add your agents.
        </GridItem>
        {(request.status == REQUEST_STATUS.PENDING ||
          request.status == REQUEST_STATUS.ACCEPTED) && (
          <Grid>
            <Button
              onClick={() => rest.onSubcontractorRequestChange(request, 1)}
              color="primary"
            >
              Accept
            </Button>

            <Button
              onClick={() => rest.onSubcontractorRequestChange(request, -1)}
              color="danger"
            >
              Reject
            </Button>
          </Grid>
        )}
      </Card>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {request.type != REQUEST_TYPE.MONITORING && (
          <>
            <Card key={shift.id} style={{ padding: 10, position: "relative" }}>
              {/* {index > 0 && (!isOffice || !!request.subcontractor_id) && (
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                  <Button onClick={() => onDeleteShift(shift)} color="danger">
                    Delete Shift
                  </Button>
                </div>
              )} */}
              <div>
                <Datetime
                  timeFormat="HH:mm"
                  dateFormat="DD/MM/YYYY"
                  value={shift.start_date}
                  onChange={(date) => onDataChanged("start_date", date)}
                  renderInput={(props, openCalendar, closeCalendar) => {
                    return (
                      <CustomInput
                        id="success"
                        labelText="Start Date"
                        // helperText={
                        //   this.state.startDateState === "error" &&
                        //   "start date is not valid"
                        // }
                        value={props.value}
                        // success={this.state.startDateState === "success"}
                        // error={this.state.startDateState === "error"}
                        inputProps={{
                          onChange: props.onChange,
                          onFocus: () => openCalendar(),
                        }}
                      />
                    );
                  }}
                />
                {request.type === REQUEST_TYPE.STATIC && (
                  <Datetime
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/YYYY"
                    value={shift.end_date}
                    onChange={(date) => onDataChanged("end_date", date)}
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return (
                        <CustomInput
                          id="success"
                          labelText="End Date"
                          value={props.value}
                          inputProps={{
                            onChange: props.onChange,
                            onFocus: () => openCalendar(),
                          }}
                        />
                      );
                    }}
                  />
                )}

                <div style={{ marginTop: 20 }}>
                  <CustomPlaceInput
                    id="success"
                    labelText="Pick up point"
                    value={shift.pup}
                    onChange={(text) => onDataChanged("pup", text)}
                  />
                </div>
                {request.type === REQUEST_TYPE.ESCORT && (
                  <div style={{ marginTop: 20 }}>
                    <CustomPlaceInput
                      id="success"
                      disabled={isOffice}
                      labelText="Drop off point"
                      value={shift.dop}
                      onChange={(text) => onDataChanged("dop", text)}
                    />
                  </div>
                )}

                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  {shift.request_team.map((team, teamIndex) => (
                    <GridContainer style={{ marginTop: 10 }} key={teamIndex}>
                      <GridItem xs={12} sm={12} md={3}>
                        <Autocomplete
                          disabled={isOffice}
                          fullWidth
                          onChange={(option, value) =>
                            onTeamChanged(teamIndex, "security_car", value)
                          }
                          value={team.security_car || null}
                          id="tags-outlined"
                          options={cars}
                          getOptionDisabled={(option) => {
                            if (option) {
                              return (
                                shift.request_team.filter(
                                  (x) =>
                                    (x.security_car &&
                                      x.security_car.id === option.id) ||
                                    x.id == option.id
                                ).length > 0
                              );
                            }
                            return "";
                          }}
                          getOptionLabel={(option) => {
                            if (option) {
                              let optionLabel = option.plate_number;
                              if (option.brand)
                                optionLabel = `${optionLabel} - ${option.brand}`;
                              if (option.color)
                                optionLabel = `${optionLabel} - ${option.color}`;

                              return optionLabel;
                            }
                          }}
                          // filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              variant="outlined"
                              label="select security car"
                              placeholder="Security car"
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Autocomplete
                          disabled={isOffice}
                          multiple
                          fullWidth
                          value={
                            team.agents && team.agents.length > 0
                              ? team.agents.map((x) => (x.agent ? x.agent : x))
                              : []
                          }
                          id="tags-outlined"
                          options={agents}
                          onChange={(option, value) =>
                            onTeamChanged(teamIndex, "agents", value)
                          }
                          getOptionDisabled={(option) => {
                            if (option) {
                              return (
                                shift.request_team.filter(
                                  (x) =>
                                    x.agents.filter(
                                      (y) =>
                                        (y.agent && y.agent.id == option.id) ||
                                        y.id == option.id
                                    ).length > 0
                                ).length > 0
                              );
                            }
                            return "";
                          }}
                          getOptionLabel={(option) => {
                            if (
                              option &&
                              option.first_name &&
                              option.last_name
                            ) {
                              let optionLabel = `${option.first_name} ${option.last_name}`;
                              if (option.phone_number)
                                optionLabel = `${optionLabel} - ${option.phone_number}`;
                              return optionLabel;
                            }
                            return "";
                          }}
                          // filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              variant="outlined"
                              label="Select security agents"
                              placeholder="Security agents"
                            />
                          )}
                        />
                      </GridItem>

                      {request.type !== REQUEST_TYPE.STATIC && (
                        <GridItem xs={12} sm={12} md={4}>
                          <Autocomplete
                            disabled={isOffice}
                            multiple
                            fullWidth
                            id="tags-outlined"
                            value={
                              team.team_client_transport &&
                              team.team_client_transport.length > 0
                                ? team.team_client_transport.map((x) =>
                                    x.client_transport ? x.client_transport : x
                                  )
                                : []
                            }
                            onChange={(option, value) =>
                              onTeamChanged(
                                teamIndex,
                                "team_client_transport",
                                value
                              )
                            }
                            options={request.client_transport}
                            getOptionDisabled={(option) => {
                              if (option) {
                                return (
                                  shift.request_team.filter(
                                    (x) =>
                                      x.id == team.id &&
                                      x.team_client_transport.filter(
                                        (y) =>
                                          (y.client_transport &&
                                            y.client_transport.id ==
                                              option.id) ||
                                          y.id == option.id
                                      ).length > 0
                                  ).length > 0
                                );
                              }
                              return "";
                            }}
                            getOptionLabel={(option) => {
                              if (option && option.truck) {
                                option = option.truck;
                                if (option) {
                                  let optionLabel = `${option.plate_number}`;
                                  if (option.brand)
                                    optionLabel = `${optionLabel} - ${option.brand}`;
                                  if (option.color)
                                    optionLabel = `${optionLabel} - ${option.color}`;
                                  return optionLabel;
                                }
                              }
                              return `Truck ${option.id}`;
                            }}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                variant="outlined"
                                label="Attach Truck(s)"
                                placeholder="Trucks"
                              />
                            )}
                          />
                        </GridItem>
                      )}

                      {teamIndex !== 0 && !isOffice && (
                        <GridItem xs={1} sm={1} md={1}>
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => onRemoveTeam(team)}
                            size="lg"
                            color="danger"
                            className="remove"
                          >
                            <DeleteForeverIcon />
                          </Button>{" "}
                        </GridItem>
                      )}
                    </GridContainer>
                  ))}
                </div>
                {!isOffice && (
                  <Button
                    onClick={() => onAddNewTeam()}
                    variant="outlined"
                    color="primary"
                  >
                    Add new team
                  </Button>
                )}
              </div>
            </Card>
          </>
        )}

        {/* <GridItem style={{ marginTop: 20 }}>
          <Typography style={{ fontSize: 16, float: "left" }} variant="h6">
            Notes (Optional)
          </Typography>
        </GridItem>
        <TextareaAutosize
          style={{
            width: "100%",
            fontSize: 20,
            float: "left",
            padding: 10,
            marginLeft: 20,
            fontFamily: "Helvetica",
            fontWeight: 200,
            marginTop: 20,
          }}
          readOnly={
            request.status === REQUEST_STATUS.ARCHIVED ||
            request.status === REQUEST_STATUS.CHECKED
          }
          disabled={isOffice}
          value={securityNotes}
          rows={5}
          name="note"
          onChange={(event) => setSecurityNotes(event.target.value)}
          placeholder="add notes here..."
        /> */}

        {saving ? (
          <div style={{ display: "table", margin: "0 auto" }}>
            <CircularProgress
              size={30}
              color="primary"
              style={{
                marginTop: 20,
              }}
            />
          </div>
        ) : (
          <>
            {((isAdmin || isSubContractor) && !isRoleOffice) ||
            (isRoleOffice && request.status === REQUEST_STATUS.ACCEPTED) ? (
              <div>
                <Button
                  fullWidth
                  disabled={
                    request.status === REQUEST_STATUS.ARCHIVED ||
                    request.status === REQUEST_STATUS.FINISHED ||
                    request.status === REQUEST_STATUS.CHECKED
                  }
                  onClick={() => {
                    // if (request.status === REQUEST_STATUS.ACCEPTED) {
                    //   alert("In Progress");
                    //   return;
                    // }
                    setAttachError(false);
                    const valid = validateShift(request);
                    if (valid === true) {
                      onSaveShift(shift);
                    } else {
                      setAttachError(valid);
                    }
                  }}
                  style={{ marginTop: 20 }}
                  size="lg"
                  color="primary"
                >
                  SAVE
                </Button>
              </div>
            ) : null}
            <Button
              size="lg"
              onClick={onClose}
              variant="contained"
              color="primary"
              simple
              fullWidth
            >
              Close
            </Button>
          </>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {isAttachError && (
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                marginLeft: 10,
                marginTop: 10,
              }}
              color="error"
            >
              {isAttachError}
            </div>
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default NewRequestShiftTeam;
