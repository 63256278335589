import React, { useState, useEffect } from "react";
import FadeIn from "react-fade-in";
// @material-ui/core components
import Fade from "@material-ui/core/Fade";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import {
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import { DateRangePicker } from "materialui-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";

// @material-ui/icons
import Lottie from "react-lottie";
import Loading from "./loading.json";
import NoResults from "./noresults.json";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArchiveIcon from "@material-ui/icons/Archive";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NavPills from "components/NavPills/NavPills";
import Primary from "components/Typography/Primary";
import RequestItem from "./Requests/RequestItem";
import Button from "components/CustomButtons/Button";
import { Button as NativeButton } from "@material-ui/core";
import RequestItemDialog from "./Requests/RequestItemDialog";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  isAdmin,
  isClient,
  isSubContractor,
} from "../../../src/constants";
import Datetime from "react-datetime";
import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { requestsActions } from "redux/requests/actions";
import { getRequests } from "redux/requests/selectors";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Typography from "@material-ui/core/Typography";
import CustomInput from "components/CustomInput/CustomInput.js";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubcontractorListDialog from "./Requests/SubcontractorListDialog.js";
import { subcontractorsActions } from "redux/subcontractors/actions.js";
import { getActiveTabStatus } from "redux/utils.js";
import { getUserOffice } from "redux/requests/selectors";
import { chatActions } from "redux/chat/actions";
import useWindowHook from "views/useWindowHook";
import NewShiftDialog from "./Requests/NewShiftDialog";

const styles = {
  trailer: {
    minWidth: 300,
    minHeight: 150,
  },
  truck: {
    width: 150,
    height: 150,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

const REQUEST_TYPE_INDEX = {
  0: "all",
  1: REQUEST_TYPE.MONITORING,
  2: REQUEST_TYPE.ESCORT,
  3: REQUEST_TYPE.STATIC,
  4: REQUEST_TYPE.INTERVENTION,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
};
const noResultsOptions = {
  loop: true,
  autoplay: true,
  animationData: NoResults,
};
export default function Dashboard() {
  const { windowIsActive } = useWindowHook();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOffice = useSelector(getUserOffice);
  const [selectedClient, setSelectedClient] = useState(-1);
  const [selectedSubc, setSelectedSubc] = useState(-1);
  const [requestDetailsVisible, setRequestDetailsVisible] = useState(false);
  const [forwardRequestVisible, setForwardRequestVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [selectedShift, setSelectedShift] = useState();
  const [shiftmodal, setShiftModal] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState("all");
  const [selectedRequestTypeIndex, setSelectedRequestTypeIndex] = useState(0);
  const [selectedRequestStatusIndex, setSelectedRequestStatusIndex] = useState(
    0
  );
  const clients = useSelector((state) => state.clients);
  const subcontractors = useSelector((state) => state.subcontractors);
  const [alert, setAlert] = useState(null);
  const [searchNumber, setSearchNumber] = useState("");
  const {
    savingSuccess,
    rejectSuccess,
    fetching,
    editConfirmSuccess,
    addOrEditShiftSuccess,
    searching,
    searchSuccess,
    searchedRequest,
    deleteSuccess,
    archiveSuccess,
    checkSuccess,
    forwardingSuccess,
    deleteShiftSuccess,
    finishShiftSuccess,
  } = useSelector((state) => state.requests);
  const { user } = useSelector((state) => state.user);
  const grouppedRequests = useSelector(getRequests);
  const [dateRange, setDateRange] = React.useState({
    startDate: moment()
      .add(-7, "days")
      .format("YYYY-MM-DD 00:00"),
    endDate: moment()
      .add(12, "months")
      .format("YYYY-MM-DD 23:59"),
  });
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (deleteShiftSuccess || finishShiftSuccess) {
      setAlert(null);
    }
  }, [deleteShiftSuccess, finishShiftSuccess]);
  useEffect(() => {
    dispatch(chatActions.toggleWindowActive(windowIsActive));
  }, [windowIsActive]);
  useEffect(() => {
    if (!isClient) {
      dispatch(chatActions.setCurrentChannel(null));
      dispatch(chatActions.getActiveChannels());
    }
  }, []);

  useEffect(() => {
    if (savingSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Request Confirmed!`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          You have confirmed the request. The client will be notified by email!
        </SweetAlert>
      );
    }
  }, [savingSuccess]);
  useEffect(() => {
    if (forwardingSuccess) {
      setForwardRequestVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Request Forwarded!`}
          onConfirm={onDismissForward}
          onCancel={onDismissForward}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Your request has been forwarded with success!
        </SweetAlert>
      );
    }
  }, [forwardingSuccess]);
  useEffect(() => {
    if (archiveSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Request Archived!`}
          onConfirm={onDismissArchive}
          onCancel={onDismissArchive}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The request is archived, you can no longer change any details of the
          request!
        </SweetAlert>
      );
    }
  }, [archiveSuccess]);
  useEffect(() => {
    if (checkSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Request Sent to Client!`}
          onConfirm={onDismissCheck}
          onCancel={onDismissCheck}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The request is sent to client, you can still update the request
          history
        </SweetAlert>
      );
    }
  }, [checkSuccess]);
  useEffect(() => {
    if (editConfirmSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={`Edit Request Confirmed!`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          You have edited the confirmation of the request. The client will be
          notified by email!
        </SweetAlert>
      );
    }
  }, [editConfirmSuccess]);
  useEffect(() => {
    if (addOrEditShiftSuccess) {
      setSelectedShift(null);
      setShiftModal(null);
      setAlert(
        <SweetAlert
          success
          title={`Success`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The client will be notified by email!
        </SweetAlert>
      );
    }
  }, [addOrEditShiftSuccess]);

  useEffect(() => {
    if (rejectSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={"Reject request"}
          onConfirm={onDismissCancel}
          onCancel={onDismissCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          You have rejected the request. The client will be notified the reason
          of rejection by email!
        </SweetAlert>
      );
    }
  }, [rejectSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setRequestDetailsVisible(false);
      setAlert(
        <SweetAlert
          success
          title={"Request removed!"}
          onConfirm={onDismissDelete}
          onCancel={onDismissDelete}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The request has been removed permanently.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (searchSuccess) {
      console.log("onOpenRequestDetails : ");
      onOpenRequestDetails(searchedRequest);
    }
  }, [searchSuccess]);

  const onOpenRequestDetails = (request) => {
    setRequestDetailsVisible(true);
    setSelectedRequest(request);
    if (!request.viewed) {
      dispatch(requestsActions.setViewRequest(request.id));
    }
  };

  const onDeleteRequest = (request) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() =>
          dispatch(requestsActions.deleteRequest({ id: request.id }))
        }
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        The request will be permanently deleted!
      </SweetAlert>
    );
  };

  const onOpenForwardRequest = (request, isCancel) => {
    setSelectedRequest(request);
    setSelectedShift(null);
    if (isCancel) {
      onForwardRequest(request.subcontractor, isCancel, request);
    } else {
      setForwardRequestVisible(true);
    }
  };
  const onOpenForwardShift = (shift, request, isCancel) => {
    setSelectedShift(shift);
    setSelectedRequest(request);
    if (isCancel) {
      onForwardRequest(shift.subcontractor, isCancel, request, shift);
    } else {
      setForwardRequestVisible(true);
    }
  };
  const onEditShift = (shift, request, isCancel) => {
    setSelectedShift(shift);
    setSelectedRequest(request);
    setShiftModal(true);
  };
  const onNewShift = (request) => {
    setSelectedShift(null);
    setSelectedRequest(request);
    setShiftModal(true);
  };

  const onDeleteShift = (shift, request) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => {
          dispatch(requestsActions.deleteShift(shift.id, request.id));
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        All the teams and their work history will be removed as well.
      </SweetAlert>
    );
  };
  const onFinishRequest = (request) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => {
          dispatch(requestsActions.finishRequest(request.id));
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Finish"
        cancelBtnText="Cancel"
        showCancel
      >
        Yes, I would like to finish the job
      </SweetAlert>
    );
  };

  const onDismissCancel = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessReject());
  };
  const onDismissDelete = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessDelete());
  };
  const onCancel = (request) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => dispatch(requestsActions.cancelRequest(request.id))}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Cancel it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You may be charged for the cancellation if it does not satisfy with our
        cancellation policy!
      </SweetAlert>
    );
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessReject());
  };
  const onDismissForward = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessForward());
  };
  const onDismissArchive = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessArchive());
  };
  const onDismissCheck = () => {
    setAlert(null);
    dispatch(requestsActions.resetSuccessCheck());
  };

  const prepareRequest = (request, cars, trucks) => {
    let security_transport = [];
    for (let i = 0; i < cars.length; i++) {
      let security_car = {};
      if (cars[i].security_car.id) {
        security_car.id = cars[i].security_car.id;
      } else {
        security_car = {
          plate_number: cars[i].security_car.plate_number,
          brand: cars[i].security_car.brand,
          color: cars[i].security_car.color,
        };
      }
      let agent_transport = [];
      for (let j = 0; j < cars[i].agent_transport.length; j++) {
        agent_transport.push({
          id: cars[i].agent_transport[j].id,
          email: cars[i].agent_transport[j].email,
        });
      }
      const client_transport_ids = trucks
        .filter((x) => x.security_transport_id == cars[i].id)
        .map((x) => x.id);
      security_transport.push({
        id: cars[i].id,
        security_car,
        agent_transport,
        client_transport_ids,
      });
    }
    return security_transport;
  };
  const prepareOptionalCars = (cars) => {
    let security_transport = [];
    for (let i = 0; i < cars.length; i++) {
      let security_car = {};
      if (cars[i].id) {
        security_car.id = cars[i].id;
      } else {
        security_car = {
          plate_number: cars[i].plate_number,
          brand: cars[i].brand,
          color: cars[i].color,
        };
      }
      security_transport.push({
        security_car,
      });
    }

    return security_transport;
  };

  const onRejectRequest = (reason, id) => {
    dispatch(requestsActions.rejectRequest(reason, id));
  };
  const onArchiveRequest = (data) => {
    dispatch(requestsActions.archiveRequest(data));
  };
  const onCheckRequest = (data) => {
    dispatch(requestsActions.checkRequest(data));
  };

  const onSaveShift = (shift, requestCopy) => {
    //requests/shift/add-edit
    let requestBody = {};
    requestBody.shift = prepareShiftRequest(
      Object.assign({}, shift),
      Object.assign({}, requestCopy)
    );
    dispatch(
      requestsActions.addOrEditShift(
        requestBody,
        requestCopy.id,
        requestCopy.is_new
      )
    );
  };
  const onConfirmRequest = (request, shifts, securityNotes) => {
    let requestBody = {};
    if (
      request.type == REQUEST_TYPE.ESCORT ||
      request.type == REQUEST_TYPE.INTERVENTION ||
      request.type == REQUEST_TYPE.STATIC
    ) {
      requestBody.shifts = prepareEscortRequest(
        [...shifts],
        request.status === REQUEST_STATUS.PENDING
      );
    }

    if (securityNotes) {
      requestBody.security_notes = securityNotes;
    }
    if (request.status === REQUEST_STATUS.PENDING)
      dispatch(
        requestsActions.confirmRequest(requestBody, request.id, request.is_new)
      );
    else {
      if (
        request.type == REQUEST_TYPE.ESCORT ||
        request.type == REQUEST_TYPE.INTERVENTION ||
        request.type == REQUEST_TYPE.STATIC
      ) {
        requestBody.teams = [].concat(
          ...requestBody.shifts.map((shift) =>
            shift.request_team.map((team) => team.id)
          )
        );
      }

      dispatch(
        requestsActions.editConfirmRequest(
          requestBody,
          request.id,
          request.is_new
        )
      );
    }
  };

  const prepareEscortRequest = (shiftsCopy, isPending) => {
    let shifts = JSON.parse(JSON.stringify(shiftsCopy));
    for (let i = 0; i < shifts.length; i++) {
      if (isPending || shifts[i].id < 1) delete shifts[i].id;
      shifts[i].start_date = moment(shifts[i].start_date.toString()).format(
        "YYYY-MM-DD H:mm:ss"
      );
      if (shifts[i].end_date)
        shifts[i].end_date = moment(shifts[i].end_date.toString()).format(
          "YYYY-MM-DD H:mm:ss"
        );
      let teams = shifts[i].request_team;
      for (let j = 0; j < teams.length; j++) {
        const agents = teams[j].agents;
        const security_car = teams[j].security_car;
        const team_client_transport = teams[j].team_client_transport || [];
        teams[j] = {
          ...teams[j],
          agents: agents
            .map((x) => (x.agent ? x.agent : x))
            .map((x) => {
              return { id: x.id, email: x.email, user_id: x.user_id };
            }),
          team_client_transport: team_client_transport.map((x) =>
            x.client_transport ? x.client_transport.id : x.id
          ),
          security_car_id: security_car ? security_car.id : null,
        };
        if (isPending || teams[j].id < 1) delete teams[j].id;
      }
      shifts[i].request_team = teams;
    }
    return shifts;
  };
  const prepareShiftRequest = (shiftCopy, requestCopy) => {
    let shift = JSON.parse(JSON.stringify(shiftCopy));
    let request = JSON.parse(JSON.stringify(requestCopy));
    shift.start_date = moment(shift.start_date.toString()).format(
      "YYYY-MM-DD H:mm:ss"
    );
    if (shift.end_date)
      shift.end_date = moment(shift.end_date.toString()).format(
        "YYYY-MM-DD H:mm:ss"
      );
    let teams = [...shift.request_team];
    for (let j = 0; j < teams.length; j++) {
      const agents = teams[j].agents;
      const security_car = teams[j].security_car;
      const team_client_transport = teams[j].team_client_transport || [];
      if (teams[j].id < 1) {
        delete teams[j].id;
      }
      teams[j] = {
        ...teams[j],
        agents: agents
          .map((x) => (x.agent ? x.agent : x))
          .map((x) => {
            return { id: x.id, email: x.email, user_id: x.user_id };
          }),
        team_client_transport: team_client_transport.map((x) =>
          x.client_transport ? x.client_transport.id : x.id
        ),
        security_car_id: security_car ? security_car.id : null,
      };
    }
    shift.request_team = [...teams];
    if (shiftCopy.id) {
      shift.teams =
        request.request_shift
          .find((x) => x.id == shiftCopy.id)
          ?.request_team.map((team) => team.id) || [];
    }

    return shift;
  };
  const onConfirmOldRequest = (
    request,
    cars,
    optionalCars,
    staticAgents,
    securityNotes,
    trucks
  ) => {
    let requestBody = {};
    if (request.type === REQUEST_TYPE.STATIC) {
      requestBody.agent_transport = staticAgents.map((x) => {
        return { id: x.id, email: x.email };
      });
      if (optionalCars.length > 0) {
        requestBody.security_transport = prepareOptionalCars(optionalCars);
      }
    }

    if (
      request.type === REQUEST_TYPE.ESCORT ||
      request.type === REQUEST_TYPE.INTERVENTION
    ) {
      requestBody.security_transport = prepareRequest(request, cars, trucks);
    }

    if (securityNotes) {
      requestBody.security_notes = securityNotes;
    }
    if (request.status === REQUEST_STATUS.PENDING)
      dispatch(
        requestsActions.confirmRequest(requestBody, request.id, request.is_new)
      );
    else
      dispatch(
        requestsActions.editConfirmRequest(
          requestBody,
          request.id,
          request.is_new
        )
      );
  };

  const pendingRequestsCount = grouppedRequests[REQUEST_STATUS.PENDING]
    ? grouppedRequests[REQUEST_STATUS.PENDING].length
    : 0;
  const acceptedRequestsCount = grouppedRequests[REQUEST_STATUS.ACCEPTED]
    ? grouppedRequests[REQUEST_STATUS.ACCEPTED].length
    : 0;
  const rejectedRequestsCount = grouppedRequests[REQUEST_STATUS.REJECTED]
    ? grouppedRequests[REQUEST_STATUS.REJECTED].length
    : 0;
  const canceledRequestsCount = grouppedRequests[REQUEST_STATUS.CANCELED]
    ? grouppedRequests[REQUEST_STATUS.CANCELED].length
    : 0;
  const finishedRequestsCount = grouppedRequests[REQUEST_STATUS.FINISHED]
    ? grouppedRequests[REQUEST_STATUS.FINISHED].length
    : 0;
  const archivedRequestsCount = grouppedRequests[REQUEST_STATUS.ARCHIVED]
    ? grouppedRequests[REQUEST_STATUS.ARCHIVED].length
    : 0;
  const checkedRequestsCount = grouppedRequests[REQUEST_STATUS.CHECKED]
    ? grouppedRequests[REQUEST_STATUS.CHECKED].length
    : 0;

  let requestsCount = grouppedRequests[
    getActiveTabStatus(selectedRequestStatusIndex)
  ]
    ? grouppedRequests[getActiveTabStatus(selectedRequestStatusIndex)].length
    : 0;
  let requests =
    selectedRequestType !== "all" &&
    grouppedRequests[getActiveTabStatus(selectedRequestStatusIndex)]
      ? grouppedRequests[getActiveTabStatus(selectedRequestStatusIndex)].filter(
          (x) => x.type === selectedRequestType
        )
      : grouppedRequests[getActiveTabStatus(selectedRequestStatusIndex)];
  if (!!requests) {
    if (
      getActiveTabStatus(selectedRequestStatusIndex) ==
        REQUEST_STATUS.CHECKED ||
      getActiveTabStatus(selectedRequestStatusIndex) ==
        REQUEST_STATUS.CANCELED ||
      getActiveTabStatus(selectedRequestStatusIndex) ==
        REQUEST_STATUS.ARCHIVED ||
      getActiveTabStatus(selectedRequestStatusIndex) == REQUEST_STATUS.FINISHED
    )
      requests = requests.sort(compare);
  }

  const onFilterRequestTypeChange = (index) => {
    setSelectedRequestTypeIndex(index);
    setSelectedRequestType(REQUEST_TYPE_INDEX[index]);
  };

  const onSearchRequest = () => {
    dispatch(requestsActions.searchRequest(searchNumber));
  };

  const onCloseDialog = () => {
    setRequestDetailsVisible(false);
    if (searchSuccess) {
      dispatch(requestsActions.resetSuccessSearch());
    }
  };

  const onForwardRequest = (data, isCancel, request, shift) => {
    if (selectedShift || shift) {
      dispatch(
        requestsActions.forwardShift({
          id: !!shift ? shift.id : selectedShift.id,
          subcontractor: data,
          request_id: !!request ? request.id : selectedRequest.id,
          isCancel,
        })
      );
    } else {
      const shifts = !!request
        ? request.request_shift
        : selectedRequest.request_shift;
      dispatch(
        requestsActions.forwardRequest({
          id: !!request ? request.id : selectedRequest.id,
          subcontractor: data,
          shifts: shifts.map((x) => x.id),
          isCancel,
        })
      );
    }
  };
  const onSubcontractorRequestChange = (request, status) => {
    if (status == 1) {
      dispatch(
        requestsActions.onSubcontractorRequestChange({
          id: request.id,
          status,
          shifts: request.request_shift.map((x) => x.id),
        })
      );
    } else {
      setAlert(
        <SweetAlert
          warning
          title="Are you sure?"
          onConfirm={() => {
            setRequestDetailsVisible(false);
            dispatch(
              requestsActions.onSubcontractorRequestChange({
                id: request.id,
                status,
                shifts: request.request_shift.map((x) => x.id),
              })
            );
            setTimeout(() => {
              setAlert(null);
            }, 1000);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Yes, Reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          The request will be removed from your Dashboard!
        </SweetAlert>
      );
    }
  };

  function compare(a, b) {
    if (b.start_date > a.start_date) return 1;
    if (a.start_date > b.start_date) return -1;

    return 0;
  }

  const toggle = () => setOpen(!open);
  const onRequestFilter = (range) => {
    toggle();
    setDateRange(range);
    if (range.startDate) {
      const startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      const endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      dispatch(
        requestsActions.fetchRequests({
          startDate,
          endDate,
          clientId: selectedClient,
          subcId: selectedSubc,
        })
      );
    }
  };

  const requestTabs =
    (isAdmin || isSubContractor) && !isOffice
      ? [
          {
            tabButton: `Pending (${pendingRequestsCount})`,
            tabIcon: MoreHorizIcon,
          },
          {
            tabButton: `Confirmed (${acceptedRequestsCount})`,
            tabIcon: BeenhereIcon,
          },
          {
            tabButton: `Rejected (${rejectedRequestsCount})`,
            tabIcon: BlockIcon,
          },
          {
            tabButton: `Canceled (${canceledRequestsCount})`,
            tabIcon: CancelIcon,
          },
          {
            tabButton: `Finished (${finishedRequestsCount})`,
            tabIcon: CheckCircleOutlineIcon,
          },
          {
            tabButton: `Checked (${checkedRequestsCount})`,
            tabIcon: CheckCircleIcon,
          },
          {
            tabButton: `Archived (${archivedRequestsCount})`,
            tabIcon: ArchiveIcon,
          },
        ]
      : [
          {
            tabButton: `Pending (${pendingRequestsCount})`,
            tabIcon: MoreHorizIcon,
          },
          {
            tabButton: `Confirmed (${acceptedRequestsCount})`,
            tabIcon: BeenhereIcon,
          },
          {
            tabButton: `Rejected (${rejectedRequestsCount})`,
            tabIcon: BlockIcon,
          },
          {
            tabButton: `Canceled (${canceledRequestsCount})`,
            tabIcon: CancelIcon,
          },
          {
            tabButton: `Finished (${finishedRequestsCount})`,
            tabIcon: CheckCircleOutlineIcon,
          },
        ];

  const minDate = isOffice ? moment().add("days", -7) : null;

  const handleChangeClient = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD 00:00");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD 23:59");
    dispatch(
      requestsActions.fetchRequests({
        startDate,
        endDate,
        clientId,
        subcId: selectedSubc,
      })
    );
  };
  const handleChangeSubc = (event) => {
    const subcId = event.target.value;
    setSelectedSubc(subcId);
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD 00:00");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD 23:59");
    dispatch(
      requestsActions.fetchRequests({
        startDate,
        endDate,
        clientId: selectedClient,
        subcId,
      })
    );
  };

  const onOpenEditStartDate = () => {};
  return (
    <div>
      <GridContainer justify="center">
        {isAdmin && (
          <>
            <GridItem style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontWeight: "bold", marginRight: 10 }}>
                Select client:{" "}
              </Typography>
              <Select
                placeholder="Clients"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClient}
                onChange={handleChangeClient}
              >
                <MenuItem value={-1}>All clients</MenuItem>
                {clients.clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography style={{ fontWeight: "bold", marginRight: 10 }}>
                {"  "}Select Subc:{" "}
              </Typography>
              <Select
                placeholder="Clients"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSubc}
                onChange={handleChangeSubc}
              >
                <MenuItem value={-1}>All Subc</MenuItem>
                {subcontractors.list.map((subc) => (
                  <MenuItem key={subc.id} value={subc.id}>
                    {subc.company_name}
                  </MenuItem>
                ))}
              </Select>
            </GridItem>
          </>
        )}

        <GridItem xs={12} sm={12} md={12}>
          <Button onClick={toggle} color="primary" round>
            <DateRangeIcon />
            {dateRange.startDate
              ? `From ${moment(dateRange.startDate).format(
                  "DD/MM/YYYY"
                )} To ${moment(dateRange.endDate).format("DD/MM/YYYY")}`
              : "Select Loading Dates"}
          </Button>
          <DateRangePicker
            open={open}
            minDate={minDate}
            color="primary"
            toggle={toggle}
            onChange={(range) => onRequestFilter(range)}
          />
          <GridItem style={{ float: "right" }}>
            <CustomInput
              id="success"
              placeholder="search request"
              maxLength={6}
              type="number"
              onChange={(text) => text.length <= 6 && setSearchNumber(text)}
              value={searchNumber}
              helperText={
                searchNumber && searchNumber.length !== 6 && "Enter 6 numbers"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searching ? (
                      <CircularProgress style={{ marginTop: -15 }} />
                    ) : (
                      <Button
                        onClick={onSearchRequest}
                        justIcon
                        round
                        color="primary"
                        disabled={searchNumber.length !== 6}
                        style={{ marginTop: -15 }}
                      >
                        <SearchIcon />
                      </Button>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography color="primary">#</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <NavPills
            color="primary"
            alignCenter
            active={selectedRequestStatusIndex}
            onTabChange={setSelectedRequestStatusIndex}
            tabs={requestTabs}
          />
          <div>
            <NavPills
              onTabChange={onFilterRequestTypeChange}
              color="primary"
              active={selectedRequestTypeIndex}
              tabs={[
                {
                  tabButton: `All (${requestsCount})`,
                },
                {
                  tabButton: `Monitoring (${
                    requestsCount
                      ? grouppedRequests[
                          getActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.MONITORING)
                          .length
                      : 0
                  })`,
                },
                {
                  tabButton: `Escort (${
                    requestsCount
                      ? grouppedRequests[
                          getActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.ESCORT).length
                      : 0
                  })`,
                },
                {
                  tabButton: `Static (${
                    requestsCount
                      ? grouppedRequests[
                          getActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.STATIC).length
                      : 0
                  })`,
                },
                {
                  tabButton: `Intervention (${
                    requestsCount
                      ? grouppedRequests[
                          getActiveTabStatus(selectedRequestStatusIndex)
                        ].filter((x) => x.type === REQUEST_TYPE.INTERVENTION)
                          .length
                      : 0
                  })`,
                },
              ]}
            />
            {!fetching && (!requests || !requests.length) && (
              <div>
                <Lottie options={noResultsOptions} height={300} width={300} />
                <Typography color="primary" style={{ textAlign: "center" }}>
                  No Requests found!
                </Typography>
              </div>
            )}
            {requests &&
              requests.map((item) => (
                <RequestItem
                  onSubcontractorRequestChange={onSubcontractorRequestChange}
                  key={item.id + ""}
                  onDeleteRequest={onDeleteRequest}
                  onOpenForwardRequest={onOpenForwardRequest}
                  onOpenForwardShift={onOpenForwardShift}
                  onEditShift={onEditShift}
                  onDeleteShift={onDeleteShift}
                  onFinishRequest={onFinishRequest}
                  onNewShift={onNewShift}
                  onOpenEditStartDate={onOpenEditStartDate}
                  // onOpenRequestDetails={onOpenRequestDetails}
                  onOpenRequestDetails={(request) =>
                    dispatch(requestsActions.searchRequest(request.identifier))
                  }
                  request={item}
                />
              ))}
          </div>
        </GridItem>
        {fetching && (
          <Lottie options={defaultOptions} height={300} width={300} />
        )}
      </GridContainer>
      {requestDetailsVisible && (
        <RequestItemDialog
          onConfirmRequest={onConfirmRequest}
          onConfirmOldRequest={onConfirmOldRequest}
          onArchiveRequest={onArchiveRequest}
          onCheckRequest={onCheckRequest}
          onRejectRequest={onRejectRequest}
          onClose={onCloseDialog}
          isModalVisible={requestDetailsVisible}
          request={searchedRequest}
          onSubcontractorRequestChange={onSubcontractorRequestChange}
        />
      )}
      {shiftmodal && selectedRequest && (
        <NewShiftDialog
          // onConfirmRequest={onConfirmRequest}
          // onConfirmOldRequest={onConfirmOldRequest}
          // onArchiveRequest={onArchiveRequest}
          // onCheckRequest={onCheckRequest}
          // onRejectRequest={onRejectRequest}
          onSaveShift={(shift) => onSaveShift(shift, selectedRequest)}
          shift={selectedShift}
          title={selectedShift ? "Edit Shift" : "New Shift"}
          onClose={() => {
            setSelectedShift(null);
            setShiftModal(null);
          }}
          isModalVisible={shiftmodal}
          request={selectedRequest}
          onSubcontractorRequestChange={onSubcontractorRequestChange}
        />
      )}
      {isAdmin && (
        <SubcontractorListDialog
          title={"Forward request to a subcontractor"}
          onClose={() => setForwardRequestVisible(false)}
          onSubmit={onForwardRequest}
          //onDataChanged={onDataChanged}
          //data={car}
          //plateNumberState={plateNumberState}
          isModalVisible={forwardRequestVisible}
          saving={false}
        />
      )}

      {alert}
    </div>
  );
}
